import dynamic from "next/dynamic";

const Home = dynamic(() => import("./Home"), {
  ssr: true,
});
const Blogs = dynamic(() => import("./Blogs"), {
  ssr: false,
});
const BlogDetail = dynamic(() => import("./BlogDetail"), {
  ssr: false,
});
const Industries = dynamic(() => import("./Industries"), {
  ssr: true,
});
const Shops = dynamic(() => import("./Shops"), {
  ssr: true,
});
const ShopDetail = dynamic(() => import("./ShopDetail"), {
  ssr: true,
});
const CaseStudies = dynamic(() => import("./CaseStudies"), {
  ssr: true,
});
const AboutUs = dynamic(() => import("./AboutUs"), {
  ssr: false,
});
const ECommerce = dynamic(() => import("./ECommerce"), {
  ssr: true,
});
const PayTo = dynamic(() => import("./PayTo"), {
  ssr: true,
});
const Xero = dynamic(() => import("./Xero"), {
  ssr: true,
});
const TyroMerchant = dynamic(() => import("./TyroMerchant"), {
  ssr: true,
});
const PaymentRequest = dynamic(() => import("./PaymentRequest"), {
  ssr: true,
});
const InStore = dynamic(() => import("./InStore"), {
  ssr: true,
});
const ForShoppers = dynamic(() => import("./ForShoppers"), {
  ssr: true,
});
const EmbeddedLoyalty = dynamic(() => import("./EmbeddedLoyalty"), {
  ssr: false,
});
const GetInTouch = dynamic(() => import("./GetInTouch"), { ssr: true });
const SelectBanks = dynamic(() => import("./SelectBanks"), { ssr: false });
const NotFound = dynamic(() => import("./404"), { ssr: false });
const InternalServer = dynamic(() => import("./500"), { ssr: false });
const Terms = dynamic(() => import("./Terms"), { ssr: false });
const Policy = dynamic(() => import("./Policy"), { ssr: false });
const DemoForecast = dynamic(() => import("./DemoForecast"), { ssr: false });
const DemoOverviewAndInsights = dynamic(
  () => import("./DemoOverviewAndInsights"),
  { ssr: false },
);

export {
  Home,
  InternalServer,
  NotFound,
  AboutUs,
  ECommerce,
  CaseStudies,
  GetInTouch,
  Blogs,
  BlogDetail,
  Industries,
  Shops,
  ShopDetail,
  Terms,
  Policy,
  SelectBanks,
  PayTo,
  Xero,
  TyroMerchant,
  PaymentRequest,
  InStore,
  ForShoppers,
  DemoForecast,
  DemoOverviewAndInsights,
  EmbeddedLoyalty,
};
