import { NotFound } from "Pages";
import { MainLayout } from "Components/Layouts";

import { METADATA_TITLE } from "Constants/Common.Constant";

const NotFoundPage = () => (
  <MainLayout title={METADATA_TITLE["404"]}>
    <NotFound />
  </MainLayout>
);

export default NotFoundPage;
