import Head from "next/head";

import {
  DEFAULT_SEO_DESCRIPTION,
  DEFAULT_SEO_TITLE,
} from "Constants/Common.Constant";
import { useRouter } from "next/router";

const LayoutHead = ({
  title,
  description,
  metaImage,
}: {
  title?: string;
  description?: string;
  metaImage?: any;
}) => {
  const router = useRouter();
  const titleMeta = title || DEFAULT_SEO_TITLE;
  const descriptionMeta = description || DEFAULT_SEO_DESCRIPTION;
  const imageMeta =
    metaImage ||
    // "https://aircrexlandingpageprod.azureedge.net/static/assets/default.png";
    "/Assets/Images/Others/thumbnail.png";

  const url = router.asPath
    ? "https://helloclever.co" + router.asPath
    : "https://helloclever.co";

  return (
    <Head>
      <title>{titleMeta}</title>
      <base href="/" />
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})
        (window,document,'script','dataLayer','GTM-TH8PT36G');`}
      </script>
      <link rel="icon" href="/Assets/Images/Icons/clever-icon.png" />
      <link rel="canonical" href={url} />

      <meta name="Content-Type" content="text/html; charset=UTF-8" />
      <meta
        name="viewport"
        content="minimum-scale=1.0, initial-scale=1.0, width=device-width"
      />

      <meta name="title" content={titleMeta} />
      <meta name="description" content={descriptionMeta} />
      <meta name="author" content="Hello Clever" />
      <meta property="og:site_name" content="Hello Clever" />

      {/* Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta name="title" property="og:title" content={titleMeta} />
      <meta name="image" property="og:image" content={imageMeta} />
      <meta
        name="description"
        property="og:description"
        content={descriptionMeta}
      />
      <meta property="fb:app_id" content="803337524350884" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image:alt" content="Hello Clever" />
      <meta name="twitter:site" content="@hello_clever" />

      <meta
        name="commission-factory-verification"
        content="af6d78a48d9242f69f2cdb9e279c36ef"
      />
    </Head>
  );
};

export default LayoutHead;
